import { PublicationFormat, PlaceStatus, type ExistingPlace } from '~/interfaces/publisher';
import type { WelcomeActionPlaneData } from '~/interfaces/publisher/UI';

export const welcomeActionPlanes: WelcomeActionPlaneData[] = [
  {
    title: 'Шаг 1',
    description:
      'Пожалуйста, подтвердите вашу почту mail@mail.ru для дальнейшей\n работы в кабинете',
    icon: { name: 'fa-solid:envelope', color: '#FFB35A' },
    button: 'Отправить код подтверждения на почту',
    emit: 'verificateEmail',
  },
  {
    title: 'Шаг 2',
    description: 'Авторизуйтесь в боте SocialData Publisher',
    icon: { name: 'f7:paperplane-fill', color: '#49C2F5' },
    button: 'Авторизуйтесь в боте',
    emit: 'authorizeInBot',
  },
];

export const actionsButtons = [
  {
    value: PlaceStatus.ARCHIVE,
    label: 'Скрыть',
  },
  {
    value: PlaceStatus.DELETED,
    label: 'Удалить',
  },
];

export const mockPlace: ExistingPlace = {
  id: 1000,
  name: 'Рандомная площадка',
  url: 'https://t.me/channelll',
  categories: [240, 242, 1019],
  private: false,
  contact: 'ыаы',
  status: PlaceStatus.ENABLED,
  cpm: 100,
  clicksCount: 1000,
  description: '',
  updatedAt: {
    date: '2024-06-07Т21:10:12',
  },
  price: {
    [PublicationFormat.post24h]: 20032,
    [PublicationFormat.post48h]: null,
    [PublicationFormat.post72h]: null,
    [PublicationFormat.fixedInTop]: null,
    [PublicationFormat.postWithoutDelete]: null,
    [PublicationFormat.story]: null,
    [PublicationFormat.repost]: 12020,
  },
  er: 20,
  subscribersStatistic: {
    count: 1231201,
    malePercent: 51,
    countries: [
      {
        location: 'Россия',
        value: 51,
      },
      {
        location: 'Германия',
        value: 32,
      },
      {
        location: 'Казахстан',
        value: 8,
      },
    ],
  },
  viewsStatistic: {
    countLast24h: 212332,
  },
  newPublicationsCount: 10,
  avatar: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg',
};

export type Ending = {
  comments: string;
  femaleParticiple: string;
  masculine: string;
  masculineParticiple: string;
  neuterEndingWithE: string;
  masculinePassive: string;
};

const numbersAndEndings = new Map<string[], Ending>([
  [
    ['0', '5', '6', '7', '8', '9'],
    {
      comments: 'иев',
      femaleParticiple: 'о',
      masculine: 'ов',
      masculineParticiple: 'ых',
      neuterEndingWithE: 'й',
      masculinePassive: 'о',
    },
  ],
  [
    ['1'],
    {
      comments: 'ий',
      femaleParticiple: 'а',
      masculine: '',
      masculineParticiple: 'ый',
      neuterEndingWithE: 'е',
      masculinePassive: '',
    },
  ],
  [
    ['2', '3', '4'],
    {
      comments: 'ия',
      femaleParticiple: 'о',
      masculine: 'а',
      masculineParticiple: 'ых',
      neuterEndingWithE: 'я',
      masculinePassive: 'о',
    },
  ],
]);

const getMapArrayKey = <T = unknown>(map: Map<T[], unknown>, elementOfArray: T) =>
  [...map.keys()].find((keyArray) => keyArray.includes(elementOfArray));

export const getEnding = (count: number) => {
  if (count > 10 && count < 20) {
    return numbersAndEndings.get(getMapArrayKey(numbersAndEndings, '0') as string[]);
  }

  const lastNumber = count.toString().split('').at(-1);

  if (!lastNumber) {
    console.log('Не найдено числа');
    return;
  }

  const keyToGet = getMapArrayKey(numbersAndEndings, lastNumber);

  if (!keyToGet) {
    console.log('Не найден подходящий вариант');
    return;
  }

  return numbersAndEndings.get(keyToGet);
};
